/* eslint-disable react/no-array-index-key */
import { Tag } from 'antd';
import i18next from 'i18next';

export const columnsVimaru = [
  {
    title: i18next.t('userCode'),
    dataIndex: 'maSV',
  },
  {
    title: i18next.t('fullName'),
    dataIndex: 'fullname',
  },
  {
    title: i18next.t('Email'),
    dataIndex: 'email',
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phone',
  },
  {
    title: i18next.t('Role'),
    dataIndex: 'type',
  },
  {
    title: i18next.t('classCode'),
    dataIndex: 'code_class',
  },
  {
    title: i18next.t('Faculty'),
    dataIndex: 'code_khoa',
  },
  {
    title: i18next.t('Specialized'),
    dataIndex: 'code_chuyeNganh',
  },
  {
    title: i18next.t('gender'),
    dataIndex: 'sex',
    render: (_, record) => {
      return <span>{record?.sex === 0 ? 'Nữ' : record?.sex === 1 ? 'Nam' : null}</span>;
    },
  },
  {
    title: i18next.t('dob'),
    dataIndex: 'birthDay',
  },
  {
    title: i18next.t('Notes'),
    dataIndex: 'note',
  },
  {
    title: i18next.t('accountStatus'),
    dataIndex: 'activeCode',
    render: (text, record) => {
      return <Tag color={text === 'ACTIVE' ? 'green' : 'red'}>{text}</Tag>;
    },
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'status',
    render: (text, record) => {
      return <Tag color={record.color}>{text}</Tag>;
    },
  },
];

export const columnsMenard = [
  {
    title: '* Mã người dùng',
    dataIndex: 'maSV',
  },
  {
    title: '* Họ và tên',
    dataIndex: 'fullname',
  },
  {
    title: '* Email',
    dataIndex: 'email',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
  },
  {
    title: '* Loại tài khoản',
    dataIndex: 'type',
  },
  {
    title: 'Mã lớp học',
    dataIndex: 'code_class',
  },
  {
    title: 'Bộ phận',
    dataIndex: 'code_shop',
  },
  {
    title: 'Chức vụ',
    dataIndex: 'code_position',
  },
  {
    title: 'Giới tính',
    dataIndex: 'sex',
    render: (_, record) => {
      return <span>{record?.sex === 0 ? 'Nữ' : record?.sex === 1 ? 'Nam' : null}</span>;
    },
  },
  {
    title: 'Ngày sinh',
    dataIndex: 'birthDay',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
  },
  {
    title: 'Ngày vào công ty',
    dataIndex: 'ngayVaoCongTy',
  },
  {
    title: i18next.t('accountStatus'),
    dataIndex: 'activeCode',
    render: (text, record) => {
      return <Tag color={text === 'ACTIVE' ? 'green' : 'red'}>{text}</Tag>;
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (text, record) => {
      return <Tag color={record.color}>{text}</Tag>;
    },
  },
];

export const columnsLG = [
  {
    title: i18next.t('userCode'),
    dataIndex: 'maSV',
  },
  {
    title: i18next.t('fullName'),
    dataIndex: 'fullname',
  },
  {
    title: i18next.t('Email'),
    dataIndex: 'email',
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phone',
  },
  {
    title: i18next.t('Role'),
    dataIndex: 'type',
  },
  {
    title: i18next.t('user:Department'),
    dataIndex: 'code_shop',
  },
  {
    title: i18next.t('classCode'),
    dataIndex: 'code_class',
  },
  {
    title: i18next.t('gender'),
    dataIndex: 'sex',
    render: (_, record) => {
      return <span>{record?.sex === 0 ? 'Nữ' : record?.sex === 1 ? 'Nam' : null}</span>;
    },
  },
  {
    title: i18next.t('dob'),
    dataIndex: 'birthDay',
  },
  {
    title: i18next.t('Notes'),
    dataIndex: 'note',
  },
  {
    title: i18next.t('accountStatus'),
    dataIndex: 'activeCode',
    render: (text, record) => {
      return <Tag color={text === 'ACTIVE' ? 'green' : 'red'}>{text}</Tag>;
    },
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'status',
    render: (text, record) => {
      return <Tag color={record.color}>{text}</Tag>;
    },
  },
];

export const columns = [
  {
    title: i18next.t('userCode'),
    dataIndex: 'maSV',
  },
  {
    title: i18next.t('fullName'),
    dataIndex: 'fullname',
  },
  {
    title: i18next.t('Email'),
    dataIndex: 'email',
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phone',
  },
  {
    title: i18next.t('Role'),
    dataIndex: 'type',
  },
  {
    title: i18next.t('classCode'),
    dataIndex: 'code_class',
  },
  {
    title: i18next.t('gender'),
    dataIndex: 'sex',
    render: (_, record) => {
      return <span>{record?.sex === 0 ? 'Nữ' : record?.sex === 1 ? 'Nam' : null}</span>;
    },
  },
  {
    title: i18next.t('auth:Birthday'),
    dataIndex: 'birthDay',
  },
  {
    title: i18next.t('Notes'),
    dataIndex: 'note',
  },
  {
    title: i18next.t('accountStatus'),
    dataIndex: 'activeCode',
    render: (text, record) => {
      return <Tag color={text === 'ACTIVE' ? 'green' : 'red'}>{text}</Tag>;
    },
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'status',
    render: (text, record) => {
      return <Tag color={record.color}>{text}</Tag>;
    },
  },
];

const getColor = (value) => {
  let color = '';
  switch (value) {
    case 'success':
      color = 'green';
      break;
    case 'failed':
      color = 'red';
      break;
    case 'new':
      color = 'blue';
      break;
    default:
      break;
  }
  return color;
};

const errorMessage = (value) => {
  let message = '';
  switch (value) {
    case 401:
      message = i18next.t('alreadyExists', { field: i18next.t('userName') });
      break;

    default:
      break;
  }
  return message;
};
